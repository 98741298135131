export default class Colors {
	static Black = '#1D1D1D';
	static Blue = '#5981EA';
	static BlueHover = '#5981EA';
	static Gray = '#3F3F3F';
	static Green = '#C8D85B';
	static GreenHover = '#A0B530';
	static LightGray = '#F5F5F5';
	static MediumGray = '#CFCFCF';
	static MediumGrayHover = '#BEBEBE';
	static White = '#FFFFFF';
}