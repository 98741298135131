// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Images
import logo from "../images/logo.png";

// Resources
import Colors from '../resources/Colors';

class Header extends Component {
	render() {
		const keyframes = Radium.keyframes({
			'0%': {
				opacity: 0.5,
				'-webkit-transform': 'scale(.5)',
				transform: 'scale(.5)'
			},
			'50%': {
				opacity: 1,
				'-webkit-transform': 'scale(1.0)',
				transform: 'scale(1.0)'
			},
			'70%': {
				'-webkit-transform': 'scale(0.9)',
				transform: 'scale(0.9)'
			},
			'100%': {
				'-webkit-transform': 'scale(1)',
				transform: 'scale(1)'
			}
		}, 'bounce');
		const styles = {
			circle: {
				animation: this.props.zoomLink ? 'x 0.7s linear infinite' : 'none',
				animationName: keyframes,
				background: '#FFFFFF',
				borderRadius: '50%',
				height: '15px',
				margin: '0 0 0 10px',
				width: '15px'
			},
			container: {
				alignItems: 'center',
				display: 'flex',
				height: this.props.status !== 'inactive' ? 'auto' : 'calc(100vh - 100px)',
				justifyContent: this.props.status !== 'inactive' ? 'space-between' : 'center',
				paddingTop: this.props.status !== 'inactive' ? "40px" : "0px",
				textAlign: this.props.status !== 'inactive' ? "left" : "center",
				'@media only screen and (max-width: 1300px)': {
					display: this.props.status !== 'inactive' ? 'block' : 'flex',
					justifyContent: 'center',
					textAlign: "center"
				}
			},
			doors: {
				color: '#AAAAAA',
				display: this.props.zoomLink ? 'none': 'block',
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 500,
				padding: 0
			},
			link: {
				textDecoration: 'none'
			},
			live: {
				alignItems: 'center',
				background: '#FA2E27',
				border: 'none',
				borderRadius: '3px',
				color: '#FFFFFF',
				cursor: 'pointer',
				display: 'flex',
				fontFamily: 'CeraPro',
				fontSize: '24px',
				fontWeight: 500,
				outline: 'none',
				padding: '15px 20px',
				':hover': {
					background: '#D80C05'
				}
			},
			logo: {
				height: "100px",
				'@media only screen and (max-width: 1300px)': {
					height: "80px"
				}
			},
			midtext: {
				color: Colors.Gray,
				fontFamily: 'CeraPro',
				fontSize: '20px',
				fontWeight: 500,
				margin: '10px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '15px'
				}
			},
			notLive: {
				alignItems: 'center',
				background: '#BFBFBF',
				border: 'none',
				borderRadius: '3px',
				color: '#FFFFFF',
				display: 'flex',
				fontFamily: 'CeraPro',
				fontSize: '24px',
				fontWeight: 500,
				outline: 'none',
				padding: '15px 20px'
			},
			right: {
				alignItems: 'flex-end',
				display: 'flex',
				flexDirection: 'column',
				'@media only screen and (max-width: 1300px)': {
					alignItems: 'center',
					justifyContent: 'center',
					margin: '20px 0 0 0'
				}
			},
			subtext: {
				color: Colors.Gray,
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 500,
				margin: '10px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '15px'
				}
			},
			welcome: {
				fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 500,
				margin: '20px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '25px'
				}
			}
		};

		let message = 'If you’re interested in a company, click “Connect”. This will send an email to you and the founders.';
		if (this.props.status === 'inactive') message = 'Check back soon! The Pear Demo Day app goes live at 9:50AM PST!';
		if (this.props.message) message = this.props.message;

		return (
			<div style={styles.container}>
				<div style={styles.left}>
					<img alt="Pear Logo" src={logo} style={styles.logo} />
					<h1 style={styles.welcome}>Welcome to Demo Day, <strong>{this.props.name}</strong>.</h1>
					{
						this.props.status !== 'inactive' &&
						<div>
							<h3 style={styles.midtext}>Companies are listed in presentation order</h3>
							<h2 style={styles.subtext}>If you’re interested in a company, click <strong>“Connect”</strong></h2>
						</div>
					}
					{
						this.props.status === 'inactive' &&
						<h2 style={styles.subtext}>Check back soon! The Pear Demo Day app goes live at 9:50AM PT!</h2>
					}
				</div>
				<div style={styles.right}>
					<a href={this.props.zoomLink} style={styles.link} target="_blank"><button style={this.props.zoomLink ? styles.live : styles.notLive}>
						Watch Demo Day Live
						<div style={styles.circle} />
					</button></a>
					{/* <h3 style={styles.doors}>Recordings available 10/15 at 5PM PT</h3> */}
				</div>
			</div>
		);
	}
}

export default Radium(Header);