// Libraries
import React, { Component } from 'react';

// Images
import logo from "../images/outline_logo.png";
import dayjs from 'dayjs';

import styles from "./styles/inactivePage.module.css";

// Get time function
import { startTime } from "../resources/Admin";

export default class InactivePage extends Component {
    state = {
        startTimeEpoch: null,
        startTimeMessage: "PearX Demo day has not started yet."
    };

    refresh = () => {
        window.location.reload();
    }

    async getStartTime() {
        let response = await startTime();
        if (response.success) {
            console.log(response);
            const formattedTime = dayjs(parseInt(response.time)).format('h:mm a');
            const formattedDate = dayjs(parseInt(response.time)).format('MMMM D, YYYY');
            
            this.setState({
                startTimeEpoch: response.time,
                startTimeMessage: (
                    <span>
                        Demo Day will start at {formattedTime} on {formattedDate}
                    </span>
                )
            });
        } else {
            console.log(response);
        }
    }

    componentDidMount() {
        this.getStartTime();
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <img alt="logo" src={logo} className={styles.logo} />
                    <h1 className={styles.message}>{this.state.startTimeMessage}</h1>
                    <h2 className={styles.loginInfo}>You're logged in as <strong>{localStorage.getItem("name")}</strong></h2>
                    <button onClick={this.refresh} className={styles.refreshButton}>Refresh Page</button>
                </div>
            </div>
        );
    }
}