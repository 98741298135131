// React
import React, { Component, PureComponent } from 'react';

// Libraries
import Radium from 'radium';
import YouTube from 'react-youtube';

// Images
import close from "../images/close.svg";
import linkedIn from "../images/linkedin.svg";

// Resources
import Colors from '../resources/Colors';
import { videoStats } from '../resources/Companies';

class SlideModal extends PureComponent {
	render() {
		return (
			<SideBar
				close={this.props.close}
				companyId={this.optionalGet(this.props.data, "id", true)}
				connectCompany={this.props.connectCompany}
				connected={this.optionalGet(this.props.data, "connected", true)}
				description={this.optionalGet(this.props.data, "description", [])}
				founders={this.optionalGet(this.props.data, "founders", [])}
				link={this.optionalGet(this.props.data, "url", "")}
				logo={this.optionalGet(this.props.data, "logo", "")}
				name={this.optionalGet(this.props.data, "name", "")}
				summary={this.optionalGet(this.props.data, "summary", "")}
				problem={this.optionalGet(this.props.data, "problem", "")}
				solution={this.optionalGet(this.props.data, "solution", "")}
				market={this.optionalGet(this.props.data, "market", "")}
				traction={this.optionalGet(this.props.data, "traction", "")}
				help={this.optionalGet(this.props.data, "help", "")}
				video={this.optionalGet(this.props.data, "video", "")}
			/>
		);
	}

	optionalGet(data, key, alt) {
		try {
			return data[key] !== null ? data[key] : alt;
		} catch (e) {
			return alt;
		}
	}
}

class SideBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			maxWidth: '700px',
			opening: true
		}

		this.close = this.close.bind(this);
		this.connectCompany = this.connectCompany.bind(this);
		this.videoInteracted = this.videoInteracted.bind(this);
	}

	componentDidUpdate() {
		this.refs.container.scrollTop = 0;
	}

	render() {
		var openKeyframes = Radium.keyframes({
			'0%': {maxWidth: this.state.opening ? '0px' : '700px'},
			'100%': {maxWidth: this.state.opening ? '700px' : '0px'},
		}, 'open');

		const styles = {
			close: {
				cursor: 'pointer',
				height: '15px', // Reduced from 20px
				width: '15px',  // Reduced from 20px
				'@media only screen and (max-width: 850px)': {
					height: '20px', // Reduced from 25px
					width: '20px'   // Reduced from 25px
				}
			},
			closeBar: {
				display: 'flex',
				justifyContent: 'flex-end',
				'@media only screen and (max-width: 850px)': {
					alignItems: 'center',
					justifyContent: 'space-between'
				}
			},
			column: {
				display: 'flex',
				flexDirection: 'column'
			},
			companyDescription: {
				color: Colors.Black,
				fontFamily: 'CeraPro',
				fontSize: '18px',
				fontWeight: 500,
				margin: '10px 0 5px 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '18px',
					margin: '10px 0 5px 0'
				}
			},
			companyInfo: {
				marginLeft: this.props.logo ? '20px' : 0,
				'@media only screen and (max-width: 850px)': {
					marginLeft: 0,
					marginTop: '25px'
				}
			},
			companyLink: {
				color: Colors.Green,
				fontFamily: 'CeraPro',
				fontSize: '18px',
				fontWeight: 300,
				margin: 0,
				padding: 0,
				textDecoration: 'underline',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '18px'
				}
			},
			companyName: {
				fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 500,
				margin: 0,
				padding: 0,
				'@media only screen and (max-width: 1300px)': {
					fontSize: '30px'
				}
			},
			companySegments: {
				padding: '10px 0 0 0'
			},
			container: {
				animation: 'x 0.3s linear',
				animationName: openKeyframes,
				background: '#FFFFFF',
				flex: 1,
				height: '100vh',
				maxWidth: this.state.maxWidth,
				overflowY: 'scroll',
				"-webkit-overflow-scrolling": 'touch',
				// overflowX: 'visible',
				padding: '0px',
				'@media only screen and (max-width: 1300px)': {
					flex: 2
				},
				'@media only screen and (max-width: 1100px)': {
					animation: 'none',
					flex: 2,
					maxWidth: '100%',
					left: 0,
					position: 'fixed',
					top: 0
				},
				zIndex: 100
			},
			content: {
				padding: '30px 40px 50px 40px',
				// width: '33vw',
				// maxWidth: '700px',
				'@media only screen and (max-width: 1100px)': {
					maxWidth: '100%',
					padding: '30px 30px 130px 30px',
					width: 'calc(100vw - 60px)'
				},
				'@media only screen and (max-width: 1100px) and (min-width: 850px)': {
					padding: '30px 100px',
					width: 'calc(100vw - 200px)'
				}
			},
			logo: {
				backgroundImage: 'url(' + this.props.logo +')',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				height: '65px',
				width: '65px',
				'@media only screen and (max-width: 1300px)': {
					height: '65px'
				},
				'@media only screen and (max-width: 850px)': {
					display: 'none'
				}
			},
			logoMobile: {
				backgroundImage: 'url(' + this.props.logo +')',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				display: 'none',
				height: '65px',
				width: '65px',
				'@media only screen and (max-width: 850px)': {
					display: 'block'
				}
			},
			row: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'row'
			},
			topRow: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				minHeight: '75px'
			},
			video: {
				height: '100%',
				width: '100%'
			},
			videoContainer: {
				background: Colors.Gray,
				height: '300px',
				margin: '15px 0 50px 0',
				minHeight: '300px',
				width: '100%'
			}
		};

		return (
			<div style={styles.container} ref="container">
				<div style={styles.content}>
					<div style={styles.column}>
						<div style={styles.closeBar}>
							<div src={this.props.logo} style={styles.logoMobile} />
							<img alt="Close" onClick={this.close} src={close} style={styles.close} />
						</div>
						<div style={styles.topRow}>
							<div style={styles.row}>
								{
									this.props.logo && <div src={this.props.logo} style={styles.logo} />
								}
								<div style={{...styles.companyInfo, ...styles.column}}>
									<h1 style={styles.companyName}>{this.props.name}</h1>
									<a href={this.props.link} rel="noopener noreferrer" style={styles.companyLink} target="_blank">{this.props.link}</a>
								</div>
							</div>
							<ConnectButton active={!this.props.connected} onClick={this.connectCompany} />
						</div>
						<h2 style={styles.companyDescription}>{this.props.summary}</h2>
					</div>
					{ this.props.video &&
						<div style={styles.videoContainer}>
							<YouTube
								videoId={this.props.video}                  // defaults -> null
								// id={string}                       // defaults -> null
								// className={string}                // defaults -> null
								// containerClassName={string}       // defaults -> ''
								opts={{
									height: '300px',
									width: '100%'
								}}                        // defaults -> {}
								// onReady={func}                    // defaults -> noop
								// onPlay={this.videoInteracted}                     // defaults -> noop
								// onPause={func}                    // defaults -> noop
								// onEnd={func}                      // defaults -> noop
								// onError={func}                    // defaults -> noop
								onStateChange={this.videoInteracted}              // defaults -> noop
								// onPlaybackRateChange={func}       // defaults -> noop
								// onPlaybackQualityChange={func}    // defaults -> noop
							/>
						</div>
					}
					<div>
						{
							this.renderFounders(this.props.founders)
						}
					</div>
					<div style={styles.companySegments}>
						{
							// this.renderSegments(this.props.description)
						}
						<CompanySegment
							content={this.props.problem}
							title={"Description"}
							key={"Problem"}
						/>
						{/* <CompanySegment
							content={this.props.problem}
							title={"Problem"}
							key={"Problem"}
						/>
						<CompanySegment
							content={this.props.solution}
							title={"Solution"}
							key={"Solution"}
						/>
						<CompanySegment
							content={this.props.market}
							title={"Market Opportunity"}
							key={"Market Opportunity"}
						/>
						<CompanySegment
							content={this.props.traction}
							title={"Traction"}
							key={"Traction"}
						/>
						<CompanySegment
							content={this.props.help}
							title={"How Can You Help Us?"}
							key={"How Can You Help Us?"}
							paddingBottom="50px"
						/> */}
					</div>
				</div>
			</div>
		);
	}

	renderFounders(data) {
		let output = [];
		for (var i = 0; i !== data.length; i++) {
			let founder = data[i];
			output.push(
				<CompanyFounder
					education={founder.education}
					email={founder.email}
					linkedin={founder.linkedin}
					name={founder.first_name + " " + founder.last_name}
					photo={founder.photo}
					role={founder.role}
					key={founder.email}
				/>
			);
		}
		return output;
	}

	renderSegments(data) {
		let output = [];
		for (var i = 0; i !== data.length; i++) {
			let segment = data[i];
			output.push(
				<CompanySegment
					content={segment.content}
					title={segment.title}
					key={segment.title}
				/>
			);
		}
		return output;
	}

	async videoInteracted(state) {
		console.log(state);
		if (state.data >= 0 && state.data < 3) {
			await videoStats(this.props.companyId, state.data);
		}
	}

	async close() {
		if (window.innerWidth < (1300 - 60)) {
			this.props.close();
		} else {
			await this.setState({opening: false, maxWidth: '0px'});
			setTimeout(this.props.close, 500);
		}
		
	}

	connectCompany() {
		if (this.props.connected) return;
		this.props.connectCompany(this.props.companyId, this.props.name);
	}
}

class ConnectButton extends Component {
	render() {
		const styles = {
			button: {
				background: Colors.Green,
				borderRadius: "3px",
				color: "#FFFFFF",
				cursor: "pointer",
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				padding: "8px 20px",
				':hover': {
					background: Colors.GreenHover
				},
				'@media only screen and (max-width: 1300px)': {
					fontSize: '13px'
				}
			},
			inactiveButton: {
				background: "#DFDFDF",
				borderRadius: "3px",
				color: "#FFFFFF",
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				padding: "8px 20px",
				'@media only screen and (max-width: 1300px)': {
					fontSize: '13px'
				}
			}
		};

		return (
			<div onClick={this.props.onClick} style={this.props.active ? styles.button : styles.inactiveButton}>
				{this.props.active ? "Connect" : "Connected"} 
			</div>
		);
	}
}

class CompanyFounder extends Component {
	render() {
		const styles = {
			container: {
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-between',
				margin: '20px 0 0 0'
			},
			email: {
				color: Colors.Green,
				fontFamily: 'CeraPro',
				fontSize: '16px',
				fontWeight: 300,
				margin: '5px 0 0 0',
				textDecoration: 'none',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '13px'
				}
			},
			founderContact: {
				paddingLeft: '25px'
			},
			founderInformation: {
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center'
			},
			linkedIn: {
				width: '30px',
				':hover': {
					opacity: 0.7
				},
				'@media only screen and (max-width: 1300px)': {
					display: 'none'
				}
			},
			name: {
				color: Colors.Black,
				fontFamily: 'CeraPro',
				fontSize: '20px',
				fontWeight: 500,
				margin: 0,
				'@media only screen and (max-width: 1300px)': {
					fontSize: '18px'
				}
			},
			photo: {
				backgroundColor: '#AFAFAF',
				backgroundImage: 'url(' + this.props.photo + ')',
				backgroundSize: 'cover',
				borderRadius: '40px',
				height: '75px',
				width: '75px',
				'@media only screen and (max-width: 1300px)': {
					height: '70px',
					width: '70px'
				}
			},
			role: {
				color: Colors.Gray,
				fontFamily: 'CeraPro',
				fontSize: '15px',
				fontWeight: 500,
				margin: '3px 0 2px 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '14px'
				}
			}
		};

		return (
			<div style={styles.container}>
				<div style={styles.founderInformation}>
					<a href={this.props.linkedin} rel="noopener noreferrer" target="_blank"><div style={styles.photo} /></a>
					<div style={styles.founderContact}>
						<h1 style={styles.name}>{this.props.name}</h1>
						<h2 style={styles.role}>{this.props.role}</h2>
						{/* <h3 style={styles.email}>{this.props.education}</h3> */}
					</div>
				</div>
				<a href={this.props.linkedin} rel="noopener noreferrer" target="_blank">
					<img alt="Profile" href={this.props.linkedin} src={linkedIn} style={styles.linkedIn} />
				</a>
			</div>
		);
	}
}

class CompanySegment extends Component {
	render() {
		const styles = {
			container: {
				marginTop: '30px',
				paddingBottom: this.props.paddingBottom ? this.props.paddingBottom : 0
			},
			content: {
				color: Colors.black,
				fontFamily: 'CeraPro',
				fontSize: '17px',
				fontWeight: 300,
				lineHeight: '22px',
				margin: '5px 0 0 0',
				'@media only screen and (max-width: 1300px)': {
					fontSize: '17px',
					lineHeight: '22px'
				}
			},
			title: {
				color: Colors.black,
				fontFamily: 'Financier',
				fontSize: '23px',
				fontWeight: 700,
				margin: 0,
				'@media only screen and (max-width: 1300px)': {
					fontSize: '23px'
				}
			}
		};

		return (
			<div style={styles.container}>
				<h1 style={styles.title}>{this.props.title}</h1>
				<p style={styles.content}>{this.props.content}</p>
			</div>
		);
	}
}

CompanyFounder= Radium(CompanyFounder);
ConnectButton = Radium(ConnectButton);
CompanySegment = Radium(CompanySegment);
SideBar = Radium(SideBar);
export default Radium(SlideModal);
