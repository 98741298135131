// React
import React, { Component } from 'react';

// Libraries
import Radium from 'radium';

// Resources
import Colors from '../resources/Colors';

import twitter from "../images/twitter.svg";
import instagram from "../images/instagram.svg";

class Footer extends Component {
	render() {
		const styles = {
			container: {
				background: Colors.Green,
				height: '150px',
				marginTop: '100px',
				position: 'relative',
				width: '100%'
			},
			content: {
				alignItems: 'flex-end',
				display: 'flex',
				justifyContent: 'space-between',
				margin: 'auto',
				maxWidth: '700px',
				padding: "20px 25% 30px 25%",
				'@media only screen and (max-width: 1300px)': {
					padding: "20px 60px 30px 60px"
				},
				'@media only screen and (max-width: 850px)': {
					alignItems: 'center'
				}
			},
			icon: {
				cursor: 'pointer',
				height: '30px',
				marginRight: '20px',
				position: 'relative',
				zIndex: 3,
				':hover': {
					opacity: 0.7
				},
				'@media only screen and (max-width: 850px)': {
					height: '25px',
					marginRight: '15px'
				}
			},
			link: {
				color: Colors.White,
				fontFamily: 'Financier',
				fontSize: '20px',
				fontWeight: 300,
				margin: 0,
				position: 'relative',
				textDecoration: 'none',
				zIndex: 3,
				':hover': {
					textDecoration: 'underline'
				},
				'@media only screen and (max-width: 850px)': {
					fontSize: '18px'
				}
			},
			mainText: {
				color: Colors.White,
				fontFamily: 'Financier',
				fontSize: '30px',
				fontWeight: 300,
				margin: 0,
				position: 'relative',
				zIndex: 3,
				'@media only screen and (max-width: 1300px)': {
					fontSize: '25px'
				}
			},
			skew: {
				background: Colors.Green,
				height: '100px',
				position: 'absolute',
				transform: 'skewY(-3deg)',
				top: '-50px',
				width: '100%'
			}
		};

		return (
			<div style={styles.container}>
				<div style={styles.skew} />
				<div style={styles.content}>
					<div>
						<a href="https://twitter.com/pearvc" rel="noopener noreferrer" target="_blank"><img alt="Twitter" key="twitter" src={twitter} style={styles.icon} /></a>
						<a href="https://www.instagram.com/pear.vc" rel="noopener noreferrer" target="_blank"><img alt="Instagram" key="instagram" src={instagram} style={styles.icon} /></a>
					</div>
					<div>
						<h1 style={styles.mainText}><strong>Pear</strong> VC</h1>
						<a href="https://pear.vc" rel="noopener noreferrer" style={styles.link} target="_blank">pear.vc</a>
					</div>
				</div>
			</div>
		);
	}
}

export default Radium(Footer);